import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

const SermonLinksContainer = styled.div`
  display: flex; 
  flex-wrap: wrap; 
  flex-direction: row;
  justify-content: space-between;
  margin: 2rem auto;
  max-width: 59rem;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    margin: 2rem auto 0;
  }
  a {
    display: flex;
    flex-flow: column;
    color: ${props => props.theme.colors.green};
    font-size: 1rem;
    font-weight: 600;
    text-decoration: none;
    transition: 0.2s;
    &:hover {
      /* background: ${props => props.theme.colors.highlight}; */
    }
    @media screen and (max-width: ${props => props.theme.responsive.medium}) {
      font-size: 14px;
    }
  }
`

function SermonLinks(props) {
  let prevButton = <div></div>;
  if (props.previous) {
    prevButton = 
      <Link to={`${props.basePath}/${props.previous.dateSlug}/`} state={{routes: props.routes}}>
        &#60; Prev
      </Link>;
  }

  return (
    <SermonLinksContainer>
      {prevButton}
      {props.next && (
        <Link to={`${props.basePath}/${props.next.dateSlug}/`} state={{routes: props.routes}}>
          Next &#62;
        </Link>
      )}
    </SermonLinksContainer>
  )
}

export default SermonLinks
