import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Container from '../components/Container'
import SEO from '../components/SEO'
import Header from '../components/SermonPage/Header'
import BreadCrumb from '../components/SermonPage/BreadCrumb'
import Media from '../components/SermonPage/Media'
import SermonDetails from '../components/SermonPage/SermonDetails'
import SermonBody from '../components/SermonPage/SermonBody'
import SermonLinks from '../components/SermonPage/SermonLinks'
import { SUNDAY_SERVICE_BASE, SERIES_BASE } from '../routes'

const SermonTemplate = ({ location, data, pageContext }) => {
    const previous = pageContext.prev
    const next = pageContext.next
    const { basePath, headerTitle } = pageContext
    const {
        title,
        dateSlug,
        speaker,
        verse,
        mediaUrl,
        series,
        body,
    } = (basePath === SUNDAY_SERVICE_BASE) ? data.contentfulSermonSunday : data.contentfulSermonWeekday;
    const parentRoute = [{ slug: `${basePath}`, label: headerTitle }];
    const currentRoute = [{ slug: `${basePath}/${dateSlug}`, label: title }];
    const routes = (!location.state || location.state.routes === undefined) ? parentRoute : location.state.routes;

    return (
        <Layout>
            <SEO title={title} />
            <Header title={headerTitle} height={'20vh'} />
            <Container>
                <BreadCrumb routes={routes.concat(currentRoute)} />
                <SermonDetails
                    date={dateSlug}
                    speaker={speaker}
                    verse={verse}
                    series={series}
                    seriesBasePath={SERIES_BASE}
                    sermonBasePath={basePath}
                />
                <Media title={title} url={mediaUrl} />
                <SermonBody body={body} />
                <SermonLinks previous={previous} next={next} basePath={basePath} routes={routes} />
            </Container>
        </Layout>
    )
}

export const query = graphql`
    query($date: Date) {
        contentfulSermonSunday(date: { eq: $date }) {
            title
            dateSlug: date(formatString: "YYYY-MM-DD")
            speaker
            verse
            mediaUrl
            series {
                name
                slug
            }
            body {
                childMarkdownRemark {
                    html
                    excerpt(pruneLength: 320)
                }
            }
        }
        contentfulSermonWeekday(date: { eq: $date }) {
            title
            dateSlug: date(formatString: "YYYY-MM-DD")
            speaker
            verse
            mediaUrl
            series {
                name
                slug
            }
            body {
                childMarkdownRemark {
                    html
                    excerpt(pruneLength: 320)
                }
            }
        }
    }
`

export default SermonTemplate
