import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

const DetailContainer = styled.div`
  /* border: 1px dotted #ccc; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin: 3rem auto;
  padding-bottom: 0.5rem;
  max-width: 59rem;
  border-top: 1px solid ${props => props.theme.colors.text};
  border-bottom: 1px solid ${props => props.theme.colors.text};
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    flex-direction: column;
    align-items: center;
    margin: initial;
    padding: initial;
  }
`

const Title = styled.div`
  font-size: 1em;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.colors.textGrey};
  word-break: keep-all;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    font-size: 14px;
  }
`
const DataText = styled.div`
  font-size: 1em;
  word-break: keep-all;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    font-size: 14px;
    line-height: 1rem;
    max-width: 4rem
  }
`
const DataContainer = styled.div`
  /* border: 1px dotted #ccc; */
  display: flex;
  width: 60%;
  margin-top: 1rem;
  flex-direction: row;
  justify-content: space-around;
  min-width: 35rem;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    min-width: initial;
    margin-top: 0.5rem;
    width: 100%;
  }
`
const Data = styled.div`
  /* border: 1px dotted #ccc; */
  font-size: 1em;
  text-align: center;
  word-break: keep-all;
  color: ${props => props.theme.colors.text};
`

const Series = styled.div`
  /* border: 1px dotted #ccc; */
  display: flex;
  width: 39%;
  justify-content: center;
  flex-direction: column;
  font-size: 1em;
  text-align: center;
  word-break: keep-all;
  margin: 1rem 0 0.5rem 0;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    margin-top: 1rem;
    width: 100%;
  }
  a {
    display: flex;
    justify-content: center;
    color: ${props => props.theme.colors.green};
    font-weight: 600;
    text-decoration: none;
    word-break: keep-all;
    @media screen and (max-width: ${props => props.theme.responsive.medium}) {
      font-size: 14px;
    }
  }
`

const SermonDetails = props => {
    return (
        <DetailContainer>
          <DataContainer>
              <Data><Title>DATE</Title><DataText>{props.date}</DataText></Data>
              <Data><Title>SPEAKER</Title><DataText>{props.speaker}</DataText></Data>
              <Data><Title>VERSE</Title><DataText>{props.verse}</DataText></Data>
            </DataContainer>
            {props.series && (
                <Series>
                    <Title>SERIES</Title>
                    <Link to={`${props.sermonBasePath}/${props.seriesBasePath}/${props.series.slug}/`}>
                        {props.series.name}
                    </Link>
                </Series>
            )}
        </DetailContainer>
    )
}

export default SermonDetails
