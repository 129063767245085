import React from 'react'
import ReactPlayer from 'react-player/lazy'
import styled from '@emotion/styled'

const Wrapper = styled.div`
    display: flex; 
    flex-wrap: wrap; 
    flex-direction: column;
    margin: 2em auto 0;
    text-align: center;
    max-width: 59rem;
`

const Title = styled.h1`
    color: black;
    font-family: "Apple SD Gothic Neo";
    font-weight: bold;
    font-size: 2em;
    line-height: 2rem;
    text-align: center;
    margin-bottom: 2rem;
    word-break: keep-all;
    @media screen and (max-width: ${props => props.theme.responsive.medium}) {
      font-size: 1.5em;
      line-height: 1.5rem;
    }
`
const Player = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
`

const PlayerWrapper = styled.div`
    position: relative;
    padding-top: 56.25%; /* 16:9 */
`

const Media = props => {
    return (
        <Wrapper>
            <Title>{props.title}</Title>
            <PlayerWrapper>
                <Player
                    className='react-player'
                    url={props.url}
                    controls={true}
                    width='100%'
                    height='100%'
                />
            </PlayerWrapper>
        </Wrapper>
    )
}
  
export default Media
